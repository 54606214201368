// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Login.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Login.jsx");
  import.meta.hot.lastModified = "1721373444722.55";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
const Login = ({
  blok
}) => {
  _s();
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const validateMobileNumber = number => {
    const regex = /^\+60\d{9}$/;
    return regex.test(number);
  };
  const handleBlur = () => {
    if (!validateMobileNumber(mobileNumber)) {
      setErrorMessage(blok.mobile_input[0].error_message_1);
    } else {
      setErrorMessage('');
    }
  };
  console.log('Login', blok);
  const signup = blok.signup_label.replace('<a/>', `<a href="${blok.sign_up_link.url}" className="text-blue-600 hover:underline">${blok.signup_link_label}</a>`);
  return <main {...storyblokEditable(blok)} key={blok._uid} className="px-4">
            
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="absolute top-5 right-5">
            <select className="border border-gray-300 rounded p-1">
            <option value="en">English</option>
            {/* Add other languages here */}
            </select>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
            <div className="mb-6">
            <img src={blok.hero.filename} alt="Hero" className="mx-auto w-40 mb-4" />
            </div>
            <h2 className="text-2xl font-bold mb-2 text-left">{blok.greeting}</h2>
            <p className="text-gray-600 mb-6 text-left">{blok.guide}</p>
            <form className="space-y-6">
            <div className="space-y-1">
                <label htmlFor="mobile-number" className="block text-left font-medium text-gray-700">{blok.mobile_input[0].label}</label>
                <input type="text" id="mobile-number" name="mobileNumber" placeholder={blok.mobile_input[0].placeholder} className="w-full p-2 border border-gray-300 rounded" value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} onBlur={handleBlur} />
                {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
            </div>
            <div className="space-y-1">
                <label htmlFor="password" className="block text-left font-medium text-gray-700">{blok.password_input[0].label}</label>
                <input type="password" id="password" name="password" placeholder={blok.password_input[0].placeholder} className="w-full p-2 border border-gray-300 rounded" />
            </div>
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                <input type="checkbox" id="remember-me" name="rememberMe" className="h-4 w-4 text-blue-600 border-gray-300 rounded" />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">{blok.remember_me}</label>
                </div>
                <a href="/forgot-password" className="text-sm text-blue-600 hover:underline">Forgot Password?</a>
            </div>
            <div className="flex items-center justify-between">
            <button type="submit" className="flex-1 bg-blue-600 text-white p-2 rounded hover:bg-blue-700 mr-2">{blok.login_button}</button>
            <img src={blok.bio_login.filename} alt="Fingerprint" className="w-12 h-12" />
          </div>
            <div className="mt-6">
                <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{
              __html: signup
            }}></p>
            </div>
            </form>
        </div>
        <footer className="mt-6 text-gray-600">
            <img src={blok.company_logo.filename} alt="Logo" className="mx-auto w-40 mb-4" />
            <p>{blok.version_number}</p>
        </footer>
        </div>

        </main>;
};
_s(Login, "tjrGy+UlOlUaSklt/aCXFYCDbWc=");
_c = Login;
export default Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;