// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Teaser.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Teaser.jsx");
  import.meta.hot.lastModified = "1721025454691.2651";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const Teaser = ({
  blok
}) => {
  return <div {...storyblokEditable(blok)} key={blok._uid} className="py-32 text-6xl text-[#50b0ae] font-bold text-center">
      {blok.headline}
    </div>;
};
_c = Teaser;
export default Teaser;
var _c;
$RefreshReg$(_c, "Teaser");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;