// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header.jsx");
  import.meta.hot.lastModified = "1721293454688.612";
}
// REMIX HMR END

import { useState } from "react";
import { Link, NavLink, useLoaderData } from "@remix-run/react";
import { getTransLink, languages, useCurrentLanguage } from "../utils/langs";
import { json } from "@remix-run/node";
import { getStoryblokApi } from "@storyblok/react";
const Header = () => {
  _s();
  const {
    header
  } = useLoaderData();
  const [openMenu, setOpenMenu] = useState(false);
  const {
    currentLanguage,
    currentPath
  } = useCurrentLanguage();

  //console.log('Header:', header);

  return <div className={header.className_header_container}>
      <div className={header.className_header_inner_container}>
        <div className={header.className_header_content}>
          <div className={header.className_logo_container}>
            <Link prefetch="intent" to={getTransLink("/", currentLanguage)}>
              <span className="sr-only">{header.site_title}</span>
              <img className={header.className_logo} src={header.company_logo.filename} alt={header.site_title} />
              <img className={header.className_logo_mobile} src={header.company_logo_mobile.filename} alt={header.site_title} />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button type="button" onClick={() => setOpenMenu(true)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
              <span className="sr-only">Open menu</span>
              {/* <!-- Heroicon name: outline/menu --> */}
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <div className={header.className_navlink_container}>
          {header.navigation_menus.map((navlink, i) => {
            return <NavLink key={i} prefetch="intent" to={getTransLink(navlink.path, currentLanguage)} className={navlink.className}>
                  {navlink.label}
                </NavLink>;
          })}
          </div>
          {languages.map(lang => {
          if (lang === currentLanguage) return null; // Don't create a switcher for the current language

          let switchLanguagePath;
          if (currentPath === "/" || currentPath === `/${currentLanguage}`) {
            switchLanguagePath = lang === "en" ? "/" : `/${lang}`; // Special case for the home page
          } else if (currentPath.startsWith(`/${currentLanguage}`)) {
            switchLanguagePath = lang === "en" ? currentPath.replace(`/${currentLanguage}`, "") // Remove current language prefix for English
            : currentPath.replace(`/${currentLanguage}`, `/${lang}`); // Replace current language prefix with new language
          } else {
            switchLanguagePath = `/${lang}${currentPath}`; // Add new language prefix to the current path
          }
          let flagEmoji;
          if (lang === "en") {
            flagEmoji = "🇺🇸";
          } else if (lang === "es") {
            flagEmoji = "🇪🇸";
          }
          return <Link key={lang} to={switchLanguagePath} className="font-bold text-3xl">
                {flagEmoji}
              </Link>;
        })}
        </div>
      </div>

      {/* <!--
        Mobile menu, show/hide based on mobile menu state.
       --> */}
      {openMenu && <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={header.company_logo_mobile.filename} alt={header.site_title} />
                </div>
                <div className="-mr-2">
                  <button type="button" onClick={() => setOpenMenu(false)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    {/* <!-- Heroicon name: outline/x --> */}
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                {header.navigation_menus.map((navlink, i) => {
                return <Link key={i} to={navlink.path} className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                        <span className="ml-3 text-base font-medium text-gray-900">
                        {navlink.label}
                        </span>
                      </Link>;
              })}
                </nav>
              </div>
            </div>
          </div>
        </div>}
    </div>;
};
_s(Header, "B/eCWolh4j1pv1XdAGBPfziMIBQ=", false, function () {
  return [useLoaderData, useCurrentLanguage];
});
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;