// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Article.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Article.jsx");
  import.meta.hot.lastModified = "1721025454603.266";
}
// REMIX HMR END

import { renderRichText } from "@storyblok/react";
const Article = ({
  blok
}) => {
  return <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        {blok.image && <img className="  md:h-96 w-full mb-10 object-cover object-center rounded" alt={blok.image.alt} src={`${blok.image?.filename}/m/1600x0`} />}
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            {blok.title}
          </h1>
          <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-600">
            {blok.subtitle}
          </h2>
          <div className="mb-8 leading-relaxed text-left max-w-full prose" dangerouslySetInnerHTML={{
          __html: renderRichText(blok.content)
        }} />
        </div>
      </div>
    </section>;
};
_c = Article;
export default Article;
var _c;
$RefreshReg$(_c, "Article");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;