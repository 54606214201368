// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import stylesheet from "~/tailwind.css";
import { json } from "@remix-run/node";
import { storyblokInit, apiPlugin, getStoryblokApi } from "@storyblok/react";
import Feature from "./components/Feature";
import Grid from "./components/Grid";
import Page from "./components/Page";
import Teaser from "./components/Teaser";
import Layout from "./components/Layout";
import Hero from "./components/Hero";
import Article from "./components/Article";
import AllArticles from "./components/AllArticles";
import PopularArticles from "./components/PopularArticles";
import Login from "./components/Login";
import { isPreview } from "~/utils/isPreview";
const components = {
  feature: Feature,
  grid: Grid,
  teaser: Teaser,
  login: Login,
  page: Page,
  hero: Hero,
  article: Article,
  "all-articles": AllArticles,
  "popular-articles": PopularArticles
};
console.log("This is root");
storyblokInit({
  accessToken: process.env.STORYBLOK_TOKEN,
  use: [apiPlugin],
  components,
  bridge: isPreview()
});
export const loader = async ({
  params
}) => {
  let lang = params.lang || "default";
  const {
    data
  } = await getStoryblokApi().get("cdn/stories/header", {
    version: "draft",
    resolve_relations: "default"
  }).catch(e => {
    console.log("e", e);
    return {
      data: null
    };
  });
  return json({
    lang,
    header: data.story.content
  });
};
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export default function App() {
  _s();
  const {
    lang,
    header
  } = useLoaderData();
  return <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{header.site_title}</title>
        <link rel="icon" href={header.site_icon_150.filename} sizes="32x32" />
        <link rel="icon" href={header.site_icon_300.filename} sizes="192x192" />
        <link rel="apple-touch-icon" href={header.site_icon_300.filename} />
        <meta name="msapplication-TileImage" content={header.site_icon_300.filename} />
        <meta test="xxx" />
        <Meta />
        <Links />
      </head>
      <body>
        <Layout>
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </Layout>
        <textarea style={{
        width: '100%',
        height: '400px'
      }} readOnly value={JSON.stringify(header, null, 2)} />
       
        <script src="//app.storyblok.com/f/storyblok-v2-latest.js" type="text/javascript">
        </script>
      </body>
    </html>;
}
_s(App, "ZjCja/z1zD26ILnXGjcbGov3F98=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;